import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
const CloseAccountComponent = ({ accounts, onCloseAccount }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage popup visibility

  const handleAccountSelection = (account) => {
    setSelectedAccount(account);
  };

  const handleCloseAccount = () => {
    if (onCloseAccount && selectedAccount) {
      onCloseAccount(selectedAccount); // Notify parent to close the account
      setSelectedAccount(null); // Reset selection
      setDialogOpen(false); // Close the dialog
    }
  };

  const handleOpenDialog = () => {
    if (selectedAccount) {
      setDialogOpen(true); // Show the confirmation dialog
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog without action
  };

  if (!accounts.length) {
    return (
      <Box textAlign="center" padding={4}>
        <Typography variant="h6">No accounts available to close.</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        paddingTop: "50px",
      }}
    >
      <Box
        padding={2}
        style={{
          maxWidth: "500px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "2px",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h5" gutterBottom align="center" color="primary">
          Settings Account
        </Typography>
        <List>
          {accounts.map((account) => (
            <React.Fragment key={account.id}>
              <ListItem
                button
                selected={selectedAccount?.id === account.id}
                onClick={() => handleAccountSelection(account)}
                sx={{
                  border: selectedAccount?.id === account.id ? "2px solid #4e2a84" : "none",
                  borderRadius: "8px",
                  mb: 1,
                }}
              >
                <ListItemText
                  primary={account.type}
                  secondary={`Account No: ${account.accountNumber} | Balance: ${account.balance}`}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        {selectedAccount && (
          <Box textAlign="center" mt={4}>
            <Typography variant="body1" gutterBottom>
              Selected Account: {selectedAccount.type} ({selectedAccount.accountNumber})
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={handleOpenDialog}
              sx={{ mt: 2 }}
            >
              Close Account
            </Button>
          </Box>
        )}
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Account Closure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the account: <strong>{selectedAccount?.type}</strong> (
            {selectedAccount?.accountNumber})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCloseAccount} variant="contained" color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
// Wrap CallbackComponent with AuthGuard and export it
const CloseAccount = ({ accounts, onCloseAccount }) => (
  <AuthGuard>
    <CloseAccountComponent accounts={accounts} onCloseAccount={onCloseAccount} />
  </AuthGuard>
);
export default CloseAccount;
