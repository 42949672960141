import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout"; // Use LogoutIcon
import { useNavigate, useLocation } from "react-router-dom";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard

const LogoutComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(5); // 5-second countdown
  const [loading, setLoading] = useState(true); // Track loading state

  // Simulate logout process
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("customer_name");
      setLoading(false); // Stop loading after logout
    }, 2000); // Simulate loading time
  }, [location.search]);

  // Countdown and auto-navigation
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate("/"); // Navigate to the main dashboard after 5 seconds
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  // Manual redirection handler
  const handleManualRedirect = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("customer_name");
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        position: "relative", // Position for the close button
      }}
    >
      {/* Manual Redirect Close Button */}
      <IconButton
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "#555",
        }}
        onClick={handleManualRedirect}
      >
        <CloseIcon />
      </IconButton>

      {/* Logout Icon */}
      {loading ? (
        <CircularProgress sx={{ color: "#4e2a84", marginBottom: "20px" }} />
      ) : (
        <LogoutIcon
          sx={{ fontSize: "100px", color: "#4e2a84", marginBottom: "20px" }}
        />
      )}

      {/* Success/Error Message */}
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", color: "#4e2a84" }}
      >
        {loading ? "Logging Out..." : "You Have Been Logged Out"}
      </Typography>

      {/* Countdown Message */}
      <Typography
        variant="body2"
        align="center"
        sx={{ color: "#888", marginTop: "20px" }}
      >
        Redirecting to the Home page in {countdown} seconds...
      </Typography>
    </Box>
  );
};

// Wrap LogoutComponent with AuthGuard and export it
const Logout = () => (
  <AuthGuard>
    <LogoutComponent />
  </AuthGuard>
);

export default Logout;
