import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Home } from "@mui/icons-material";
import { Receipt } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";

const Footer = ({ step, setStep }) => {
  return (
    <BottomNavigation
      showLabels
      value={step}
      onChange={(event, newValue) => setStep(newValue)}
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <BottomNavigationAction label="Home" icon={<Home />} value={1} />
      <BottomNavigationAction label ="Transactions"icon={<Receipt />}value={5} />
      <BottomNavigationAction label="Settings" icon={<Settings  />} value={4} />
    </BottomNavigation>
  );
};

export default Footer;
