import React from "react";
import { useAuth } from "../authen/AuthContext";
import { oauthConfig } from "../config/oauthConfig";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Validate OAuth configuration
    if (!oauthConfig || !oauthConfig.authorizationEndpoint || !oauthConfig.clientId) {
      console.error("OAuth configuration is missing or invalid.");
      return <div>Error: Invalid OAuth Configuration</div>;
    }

    // Redirect to the OAuth login page
    const nonce = generateRandomString();
    const state = generateRandomString();
    const authorizationUrl = `${oauthConfig.authorizationEndpoint}?client_id=${oauthConfig.clientId}&response_type=${oauthConfig.responseType}&redirect_uri=${encodeURIComponent(
      oauthConfig.redirectUri
    )}&response_mode=${oauthConfig.responseMode}&scope=${encodeURIComponent(
      oauthConfig.scopes.join(" ")
    )}&state=${state}&nonce=${nonce}`;

    console.log("Redirecting to:", authorizationUrl);
    window.location.href = authorizationUrl;
    return null; // Do not render anything after initiating redirect
  }

  return children; // Render the component if authenticated
};

export const generateRandomString = (length = 32) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).slice(-2)).join("");
};

export default AuthGuard;
