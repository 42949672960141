import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { oauthConfig } from "../config/oauthConfig";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard
import { isTokenExpired } from "../utils/authUtils";
import { useNavigate } from "react-router-dom";

const CreateSubAccountComponent = ({ selectedAccount, products, onCreate }) => {
  const [formData, setFormData] = useState({
    parentAccountId: selectedAccount,
    productId: "",
    pocketName: "",
  });

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  // Fetch product list when the component loads
  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      navigate('/logout');
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true); // Show the popup on form submission
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      // Make API call to create account
      const response = await axios.post(`${oauthConfig.createSubAccountService}`, formData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (onCreate) {
        onCreate(response.data);
      }

      // Reset the form
      setFormData({ productId: "", pocketName: "", parentAccountId: ""});
      setOpen(false);
    } catch (error) {
      console.error("Failed to create account:", error);
      alert("There was an error creating the account. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false); // Close the popup without submitting
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          paddingTop: "50px",
        }}
      >
        <Box
          padding={2}
          style={{
            maxWidth: "500px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "2px",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h5" gutterBottom align="center" color="primary">
            Create New Pocket
          </Typography>
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Parent Account Number"
                  name="parentAccountNo"
                  value={selectedAccount?.account_number || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Pocket Name"
                  name="pocketName"
                  value={formData.pocketName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="product-label">Select Product</InputLabel>
                  <Select
                    labelId="product-label"
                    name="productId"
                    value={formData.productId}
                    onChange={handleChange}
                    required
                  >
                    {products
                      .filter((product) => product.product_type === "Pocket")
                      .map((product) => (
                        <MenuItem key={product.product_id} value={product.product_id}>
                          {product.product_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={3} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#4e2a84", color: "#fff" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating..." : "Create Pocket"}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      {/* Popup Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Pocket Creation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to create the following pocket?
          </DialogContentText>
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Pocket Name:</strong> {formData.pocketName}
            </Typography>
            <Typography variant="body1">
              <strong>Product:</strong>{" "}
              {products.find((p) => p.product_id === formData.productId)?.product_name || "Unknown"}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateSubAccount = ({ selectedAccount, products, onCreate }) => (
  <AuthGuard>
    <CreateSubAccountComponent
      selectedAccount={selectedAccount}
      products={products}
      onCreate={onCreate} />
  </AuthGuard>

);

export default CreateSubAccount;
