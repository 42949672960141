import React from "react";
import { Box, Typography } from "@mui/material";

const CardItem = ({ title, icon, onClick }) => {
  return (
    <Box
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: "#ffffff",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      {icon && <Box style={{ marginBottom: "10px" }}>{icon}</Box>}
      <Typography variant="body1" style={{ fontWeight: "bold", color: "#4e2a84" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default CardItem;
