import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { createTheme, ThemeProvider, CssBaseline, Box, Typography } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Router components
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./pages/Main";
import Success from "./pages/Success";
import CreateAccount from "./pages/CreateAccount";
import CloseAccount from "./pages/CloseAccount";
import TransactionHistory from "./pages/TransactionHistory";
import Transfer from "./pages/Transfer";
import Callback from "./pages/Callback";
import Logout from "./pages/Logout";
import MainAccount from "./pages/MainAccount";
import CreateSubAccount from "./pages/CreateSubAccount";
import accentureHeaderLogo from "./assets/accenture_header.png";
import axios from "axios";
import { oauthConfig } from "../src/config/oauthConfig";
const theme = createTheme({
  palette: {
    primary: { main: "#4e2a84" },
    secondary: { main: "#ffffff" },
    background: { default: "#f5f5f5", paper: "#ffffff" },
    text: { primary: "#000000", secondary: "#4e2a84" },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h5: { fontWeight: 700 },
    body1: { fontSize: "1rem" },
    button: { textTransform: "none" },
  },
});

const App = () => {
  const token = localStorage.getItem("authToken");
  const [showLanding, setShowLanding] = useState(true);
  const [step, setStep] = useState(1);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [pockets, setPockets] = useState([]);

  const [products, setProducts] = useState([]);

  const [accounts, setAccounts] = useState([
    { id: 1, type: "Savings Account", balance: "$7,000", accountNumber: "123-456-789" },
    { id: 2, type: "Current Account", balance: "$3,000", accountNumber: "987-654-321" },
    { id: 3, type: "Fixed Deposit", balance: "$10,000", accountNumber: "456-789-123" },
  ]);

  const transactions = [
    { id: 1, accountId: 1, date: "2023-11-15", description: "ATM Withdrawal", amount: "-$200", type: "Debit" },
    { id: 2, accountId: 1, date: "2023-11-14", description: "Salary Credit", amount: "+$3,000", type: "Credit" },
    { id: 3, accountId: 2, date: "2023-11-13", description: "Bill Payment", amount: "-$100", type: "Debit" },
    { id: 4, accountId: 3, date: "2023-11-12", description: "Interest Credit", amount: "+$500", type: "Credit" },
  ];

  const handleCloseAccount = (account) => {
    setAccounts((prevAccounts) => prevAccounts.filter((a) => a.id !== account.id));
    alert(`Closed account: ${account.type} (${account.accountNumber})`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${oauthConfig.listProductService}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        const productList = response.data.data;
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      console.log(token)
      try {
        console.log(oauthConfig.listAccountService)
        const response = await axios.get(`${oauthConfig.listAccountService}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        const accountList = response.data.data;
        console.log(accountList)

        setAccounts(accountList);
      } catch (error) {
        console.error("Error fetching account list:", error);
      }
    };

    fetchAccounts();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                {showLanding && (
                  <motion.div
                    className="landing-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    onAnimationComplete={() => setTimeout(() => setShowLanding(false), 3000)}
                  >
                    <motion.div
                      className="logo-container"
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 1.5 }}
                    >
                      <Typography variant="h6" align="center" style={{ color: "white" }}>
                        <img src={accentureHeaderLogo} alt="Accenture Logo" className="logo" />
                      </Typography>
                    </motion.div>
                    <motion.div
                      className="welcome-text"
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 1, delay: 1.5 }}
                    >
                      <h2>Welcome to Mockup Banking</h2>
                      <p>Innovate | Collaborate | Transform</p>
                    </motion.div>
                  </motion.div>
                )}
                {!showLanding && (
                  <Box>
                    <Header />
                    {step === 1 && <Main
                      setStep={setStep}
                      setSelectedAccount={setSelectedAccount}
                      setPockets={setPockets}
                      accounts={accounts}
                      products={products}
                    />}
                    {step === 2 && <Success />}
                    {step === 3 && <CreateAccount
                      products={products}
                      onCreate={() => setStep(1)}
                    />}
                    {step === 4 && (
                      <CloseAccount accounts={accounts} onCloseAccount={handleCloseAccount} />
                    )}
                    {step === 5 && <TransactionHistory accounts={accounts} transactions={transactions} />}
                    {step === 6 && <Transfer accounts={accounts} onTransfer={() => setStep(1)} />}
                    {step === 7 && <MainAccount
                      setStep={setStep}
                      selectedAccount={selectedAccount}
                      pockets={pockets}
                      setPockets={setPockets}
                    />}
                    {step === 8 && <CreateSubAccount
                      selectedAccount={selectedAccount}
                      products={products}
                      onCreate={() => setStep(7)} />}
                    <Footer step={step} setStep={setStep} />
                  </Box>
                )}
              </div>
            }
          />
          <Route path="/transfer" element={<Transfer accounts={accounts} onTransfer={() => setStep(6)} />} />
          <Route path="/success" element={<Success />} />
          <Route path="/callback" element={<Callback />} />
          {/* <Route path="/creat-account" element={<CreateAccount />} /> */}
          {/* <Route path="/main-account" element={<MainAccount />} /> */}
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
