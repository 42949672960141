import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../authen/AuthContext";
import axios from "axios";
import { oauthConfig } from "../config/oauthConfig";
import { v4 as uuidv4 } from "uuid";

const CallbackComponent = () => {
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState("loading"); // 'loading', 'success', or 'error'
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const params = new URLSearchParams(location.search);
      const authorizationCode = params.get("code");
      const errorParam = params.get("error");

      if (authorizationCode) {
        // console.log("Authorization code:", authorizationCode);
        const requestData = {
          request_id: uuidv4(),
          auth_request: {
            redirect_uri: `${oauthConfig.redirectUri}`,
            code: authorizationCode,
          },
        };

        try {
          // Make API call to exchange the authorization code for a token
          const responseAuth = await axios.post(`${oauthConfig.authenService}`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
            responseType: "json",
          });
          //console.log("responseAuth :", JSON.stringify(responseAuth));
          const token = responseAuth.data.auth_data.access_token;
          const name = responseAuth.data.auth_data.customer_name;
          const id = responseAuth.data.auth_data.customer_id;
          localStorage.setItem("access_token", token); 
          localStorage.setItem("customer_id", id);
          localStorage.setItem("customer_name", name);
          setIsAuthenticated(true);
          setStatus("success");
        } catch (error) {
          // Handle error during token exchange
          console.error("Error during token exchange:", error.response?.data || error.message);
          localStorage.removeItem("access_token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("customer_name");
          setIsAuthenticated(false);
          setStatus("error");
        }
      } else {
        // Handle OAuth error
        console.error("Error during OAuth callback:", errorParam);
        localStorage.removeItem("access_token");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("customer_name");
        setIsAuthenticated(false);
        setStatus("error");
      }
    };

    handleOAuthCallback();
  }, [location.search, setIsAuthenticated]);

  // Handle countdown and redirection
  useEffect(() => {
    if (status === "loading") return; // Skip countdown during loading

    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate(status === "success" ? "/" : "/"); // Redirect based on status
    }, 2000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [status, navigate]);

  // Manual redirection handler
  const handleManualRedirect = () => {
    navigate(status === "success" ? "/" : "/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        position: "relative", // Position for the close button
      }}
    >
      {/* Manual Redirect Close Button */}
      <IconButton
        sx={{
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "#555",
        }}
        onClick={handleManualRedirect}
      >
        <CloseIcon />
      </IconButton>

      {/* Loading, Success, or Error Icon */}
      {status === "loading" && <CircularProgress sx={{ color: "#4e2a84", marginBottom: "20px" }} />}
      {status === "success" && (
        <CheckCircleIcon sx={{ fontSize: "100px", color: "green", marginBottom: "20px" }} />
      )}
      {status === "error" && (
        <ErrorIcon sx={{ fontSize: "100px", color: "red", marginBottom: "20px" }} />
      )}

      {/* Status Message */}
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", color: "#4e2a84" }}
      >
        {status === "loading" && "Validating Callback..."}
        {status === "success" && "Callback Verified!"}
        {status === "error" && "Callback Validation Failed"}
      </Typography>

      {/* Countdown Message */}
      {status !== "loading" && (
        <Typography
          variant="body2"
          align="center"
          sx={{ color: "#888", marginTop: "20px" }}
        >
          Redirecting to the {status === "success" ? "Home" : "Login"} page in {countdown} seconds...
        </Typography>
      )}
    </Box>
  );
};

export default CallbackComponent;
