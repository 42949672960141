import React from "react";
import { AppBar, Typography, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout"; // Import Logout Icon
import { oauthConfig } from "../config/oauthConfig";
import accentureHeaderLogo from "../assets/accenture_header.png";
const Header = () => {
  const handleSignOut = () => {
    // Redirect to the Microsoft logout URL
    //console.log("Logging out..."+oauthConfig.logouttUri);
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${oauthConfig.logouttUri}`;
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#4e2a84",
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* App Title */}
      <Typography variant="h6" align="center" style={{ color: "white" }}>
        <img src={accentureHeaderLogo } alt="Accenture Logo" className="logo" />
      </Typography>

      {/* Sign Out Button */}
      <IconButton
        onClick={handleSignOut}
        sx={{
          color: "white",
        }}
      >
        <LogoutIcon />
      </IconButton>
    </AppBar>
  );
};

export default Header;
