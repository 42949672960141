import React, { useState } from "react";
import {
    Box,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard

const TransactionHistoryComponent = ({ accounts = [], transactions = [] }) => {
    const [selectedAccount, setSelectedAccount] = useState("");

    const handleAccountChange = (event) => {
        setSelectedAccount(event.target.value);
    };

    // Filter transactions based on the selected account
    const filteredTransactions = transactions.filter(
        (transaction) => transaction.accountId === selectedAccount
    );

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                minHeight: "100vh",
                paddingTop: "50px",
            }}
        >
            <Box
                padding={2}
                style={{
                    maxWidth: "500px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    backgroundColor: "#fff",
                }}
            >
                <Typography variant="h5" gutterBottom align="center" color="primary">
                    Transaction History
                </Typography>

                {/* Dropdown to Select Account */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Account</InputLabel>
                    <Select value={selectedAccount} onChange={handleAccountChange}>
                        {accounts.map((account) => (
                            <MenuItem key={account.id} value={account.id}>
                                {account.type} ({account.accountNumber})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Transaction Table */}
                {selectedAccount && (
                    <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTransactions.map((transaction) => (
                                    <TableRow key={transaction.id}>
                                        <TableCell>{transaction.date}</TableCell>
                                        <TableCell>{transaction.description}</TableCell>
                                        <TableCell>{transaction.amount}</TableCell>
                                        <TableCell>{transaction.type}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {!selectedAccount && (
                    <Typography
                        variant="body1"
                        align="center"
                        color="text.secondary"
                        sx={{ marginTop: 4 }}
                    >
                        Please select an account to view its transaction history.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
const TransactionHistory = ({ accounts, transactions }) => (
    <AuthGuard>
        <TransactionHistoryComponent accounts={accounts} transactions={transactions} />
    </AuthGuard>
);
export default TransactionHistory;
