export const oauthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authorizationEndpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  logouttUri: process.env.REACT_APP_LOGOUT_URI,
  scopes: process.env.REACT_APP_SCOPES?.split(",") || ["openid"],
  responseType: process.env.REACT_APP_RESPONSE_TYPE,
  responseMode: process.env.REACT_APP_RESPONSE_MODE,
  tenantId: process.env.REACT_APP_TENANT_ID,
  authenService: process.env.REACT_APP_AUTHEN_SERVICE,
  profileService: process.env.REACT_APP_PROFILE_SERVICE,
  createAccountService: process.env.REACT_APP_ACCOUNT_SERVICE,
  createSubAccountService: process.env.REACT_APP_CREATE_SUB_ACCOUNT_SERVICE,
  listProductService: process.env.REACT_APP_LIST_PRODUCT_SERVICE,
  listAccountService: process.env.REACT_APP_LIST_ACCOUNT_SERVICE
};
