import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthGuard from "../authen/AuthGuard"; // Ensure correct path to AuthGuard

const TransferComponent = ({ accounts, onTransfer }) => {
  const [formData, setFormData] = useState({
    senderAccount: "",
    recipientAccount: "",
    amount: "",
    description: "",
  });

  const [openDialog, setOpenDialog] = useState(false); // State for popup dialog
  const navigate = useNavigate(); // Initialize navigate

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenDialog(true); // Open confirmation popup
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the popup
  };

  const handleConfirm = () => {
    setOpenDialog(false); // Close the popup
    if (onTransfer) onTransfer(formData); // Call the transfer handler if provided
    navigate("/success"); // Navigate to the Success page
  };

  if (!accounts || accounts.length === 0) {
    return (
      <Box sx={{ textAlign: "center", paddingTop: "50px" }}>
        <Typography variant="h6" color="error">
          No accounts available for transfer.
        </Typography>
      </Box>
    );
  }

  return (
    <AuthGuard>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          paddingTop: "50px",
        }}
      >
        <Box
          padding={4}
          style={{
            maxWidth: "500px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h5" gutterBottom align="center" color="primary">
            Transfer Funds
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Sender Account */}
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Sender Account"
                  name="senderAccount"
                  value={formData.senderAccount}
                  onChange={handleChange}
                  required
                >
                  {accounts.map((account) => (
                    <MenuItem key={account.id} value={account.accountNumber}>
                      {account.type} ({account.accountNumber})
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Recipient Account */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Recipient Account Number"
                  name="recipientAccount"
                  value={formData.recipientAccount}
                  onChange={handleChange}
                  required
                  placeholder="Enter recipient's account number"
                />
              </Grid>

              {/* Transfer Amount */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  required
                  type="number"
                  placeholder="Enter amount to transfer"
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Add a note (optional)"
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Box mt={3} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#4e2a84", color: "#fff" }}
              >
                Transfer
              </Button>
            </Box>
          </form>
        </Box>

        {/* Dialog for Confirmation */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Confirm Transfer</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to transfer <strong>{formData.amount}</strong>{" "}
              from account <strong>{formData.senderAccount}</strong> to{" "}
              <strong>{formData.recipientAccount}</strong>?
            </Typography>
            {formData.description && (
              <Typography mt={2}>
                <strong>Note:</strong> {formData.description}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </AuthGuard>
  );
};
const Transfer = ({ accounts, transactions }) => (
  <AuthGuard>
      <TransferComponent accounts={accounts} transactions={transactions} />
  </AuthGuard>
);
export default Transfer;
