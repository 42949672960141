import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CardItem from "../components/CardItem"; // Ensure this is the correct path
import AuthGuard from "../authen/AuthGuard"; // Import AuthGuard


const MainComponent = ({ setStep, setSelectedAccount, accounts, products, setPockets }) => {
  const [name, setName] = useState("Mate");
  const [emoji, setEmoji] = useState("😀");

  useEffect(() => {
    const storedName = localStorage.getItem("customer_name");
    if (storedName) {
      setName(storedName);
    }
    // Array of emojis
    const emojis = ["😀", "😁", "😂", "😊", "😍", "😎", "😇", "🥳", "🤩", "😄"];
    // Randomly select an emoji
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    setEmoji(randomEmoji);
  }, []);

  const handleTransfer = (account) => {
    setSelectedAccount(account); // Pass the selected account to the parent
    setStep(6); // Navigate to the "Transfer" page
  };

  const handleMainAccount = (selectedAccount, accounts) => {
    const pockets = accounts.filter((account) => account.account_type === "Pocket" && account.parent_account_id === selectedAccount.account_id)
    setSelectedAccount(selectedAccount); // Pass the selected account to the parent
    setPockets(pockets)
    setStep(7); // Navigate to the "Main Account" page
  };

  return (
    <Box>
      {/* Greeting Section */}
      <Box padding={2}>
        <Typography variant="h6" gutterBottom>
          Hello, {name} ! {emoji}
        </Typography>
      </Box>

      {/* Menu Options */}
      <Box padding={2}>
        <Typography variant="h6" gutterBottom>
          Select Menu
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CardItem
              title="Top Up"
              icon={<AttachMoneyIcon style={{ fontSize: 40, color: "#4e2a84" }} />}
              onClick={() => setStep(6)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CardItem
              title="Transfer"
              icon={<CompareArrowsIcon style={{ fontSize: 40, color: "#4e2a84" }} />}
              onClick={() => setStep(6)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CardItem
              title="Pay Bills"
              icon={<ReceiptIcon style={{ fontSize: 40, color: "#4e2a84" }} />}
              onClick={() => setStep(6)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CardItem
              title="Create Account"
              icon={<AddCircleIcon style={{ fontSize: 40, color: "#4e2a84" }} />}
              onClick={() => setStep(3)}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Account List */}
      <Box padding={2}>
        <Typography variant="h6" gutterBottom>
          Your Accounts
        </Typography>
        <List>
          {accounts
            .filter((account) => account.account_type === "Savings")
            .map((account) => (
              <div key={account.account_number}>
                <ListItem
                  button
                  onClick={() => handleMainAccount(account, accounts)}
                  component="div" // Ensure this is rendered as a valid HTML element
                >
                  <ListItemIcon>
                    <Avatar style={{ backgroundColor: "#4e2a84" }}>
                      <AccountBalanceIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography style={{ color: "#4e2a84" }}>
                        {account.account_type}
                      </Typography>
                    }
                    secondary={
                      <Typography style={{ color: "#4e2a84" }}>
                        {`Account No: ${account.account_number} | Balance: ${account.balance}`}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
        </List>
      </Box>
    </Box>
  );
};

const Main = ({ setStep, setSelectedAccount, accounts, products, setPockets }) => (
  <AuthGuard>
    <MainComponent
      setStep={setStep}
      setSelectedAccount={setSelectedAccount}
      accounts={accounts}
      products={products}
      setPockets={setPockets}
    />
  </AuthGuard>
);

export default Main;
